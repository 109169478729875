import { tiendasApi } from '../api'

export const loginSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation({
            query: ({email, contrasena}) => ({
                url: 'web/self/login',
                method: 'POST',
                body: { "email": `${email}`, "contrasena": `${contrasena}` }
            })
        })
    })
})

export const {
    useLoginMutation
} = loginSlice