import { createSlice } from "@reduxjs/toolkit"


export const productosSlice = createSlice({
    name: 'productos',
    initialState: {
        _id: null,
        titulo: null,
        url: null,
        imagen: null,
        __v: null
    },
    reducers: {
        getProductos: (state, action) => {
            state._id = action.payload._id 
            state.titulo = action.payload.titulo
            state.url = action.payload.url
            state.imagen = action.payload.imagen
            state.__v = action.payload.__v
        }
    }
});

export const { getProductos } = productosSlice.actions

