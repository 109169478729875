import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        nombre: null,
        email: null,
        token: null
    },
    reducers: {
        setCredentials: (state, action) => {
            state.nombre = action.payload.nombre;
            state.email = action.payload.email;
            state.token = action.payload.token;
            localStorage.setItem("token", action.payload.token);
        },
        forzarRefreshToken: (state, action) => {
            state.token = null;
        },
        logOut: (state, action) => {
            state.nombre = null;
            state.email = null;
            state.token = null;
            localStorage.removeItem("token");
        },
    }
});

export const { setCredentials, forzarRefreshToken, logOut } = loginSlice.actions;

export const loginOptions = (state) => state.login

//export const getUsuarioNombre = (state) => state.auth.nombre;
//export const getUsuarioToken = (state) => state.auth.token;



