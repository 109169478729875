import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'



export default function ErrorMessage({error}) {

    return (

        <>
             <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <p align="center">
                                <i className="pi pi-exclamation-triangle" style={{ color: '#ff8637', fontSize: '2rem' }}></i>
                                <p>¡Cuidado!</p>
                                <p>{error}</p>
                                <p><Button startIcon={<ArrowBackIosIcon />} type="submit" variant="outlined" color="success" onClick={() => window.location.reload(false)}>
                                    VOLVER 
                                </Button></p>
                            </p>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}