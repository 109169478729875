import { tiendasApi } from '../api'

export const productosSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getProductos: builder.mutation({
            query: () => ({
                url: 'web/productos',
                method: 'GET'
            })
        }),
    })
})

export const {
    useGetProductosMutation,
} = productosSlice