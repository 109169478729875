import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { sendEmail } from '../../../store/slices/recuperar-clave'
import { useRecoveryEmailMutation } from '../../../store/api/endpoints/recuperar-clave'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import Input from '../../elementos/Input'
import Loading from '../../elementos/Loading'
import SuccessMessage from '../../elementos/SuccessMessage'
import ErrorMessage from '../../elementos/ErrorMessage'

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import '../../../assets/css/Login.css'

const RecuperarClave = () => {

    const [email, setEmail] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        setErrorMessage("")
    }, [email])
    
    const [triggerSendEmail, { isLoading: isLoadingEnviarEmail, isSuccess: isSuccessEnviarEmail}] = useRecoveryEmailMutation()

    const submitForm = async (e) => {

        e.preventDefault()

        try {
            const userEmail = await triggerSendEmail({email: email}).unwrap()
            dispatch(sendEmail(userEmail))

        } catch (err) {
            setErrorMessage(err)
        }
        
    }

    if (isLoadingEnviarEmail) return (<Loading />)

    if (errorMessage) return (<ErrorMessage error={errorMessage.data.message} />)

    if (isSuccessEnviarEmail) return (<SuccessMessage email={email} />)

    return (
        <>
            
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <form onSubmit={submitForm}>
                                <h2 style={{ letterSpacing: '1px', fontWeight: '600' }}>Recuperá tu clave</h2>
                                
                                <p>Para recuperar tu clave, ingresá tu email:</p>
                                <br />

                                <Input
                                    tituloMini="Email"
                                    type="text"
                                    value={email}
                                    onChange={setEmail}
                                    icon="pi pi-at"
                                    required
                                />
                                
                                <br />
                                <p><Button type="submit" variant="outlined" color="success" endIcon={<KeyboardArrowRightIcon />}>
                                    Recuperar Clave 
                                </Button></p>
                            </form>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}

export default RecuperarClave