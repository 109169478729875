import { React, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import Home from './Home'


const PageNotFound = () => {


    return (
        <>

            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <p align="center">
                                <i className="pi pi-exclamation-triangle" style={{ color: '#ff8637', fontSize: '2rem' }}></i>
                                <p>¡Cuidado!</p>
                                <p>Al parecer el link que deseas visitar está roto o no funciona. Por favor vuelve al home desde <Link to="/" exact component={Home}> aquí</Link></p>
                            </p>
                        </div>
                    </Grid>
                </Grid >
            </Container>
            
        </>
    )

}

export default PageNotFound