import { createSlice } from "@reduxjs/toolkit"


export const descuentosSlice = createSlice({
    name: 'descuentos',
    initialState: {
        _id: null,
        titulo: null,
        descripcion: null,
        fechaInicio: null,
        fechaFin: null,
        imagen: null,
        __v: null
    },
    reducers: {
        getDescuentos: (state, action) => {
            state._id = action.payload._id 
            state.titulo = action.payload.titulo
            state.descripcion = action.payload.descripcion
            state.fechaInicio = action.payload.fechaInicio
            state.fechaFin = action.payload.fechaFin
            state.imagen = action.payload.imagen
            state.__v = action.payload.__v
        }
    }
});

export const { getDescuentos } = descuentosSlice.actions

