import Login from '../vistas/auth/Login'
import { useNavigate, Link } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

export default function PasswordRecoveryMessage({message}) {

    const navigate = useNavigate()
    const returnToLogin = () => { navigate("/login") }

    return (

        <>
             <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <p align="center">
                                <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '2rem' }}></i>
                                <p>¡Clave Modificada!</p>
                                <p>Se modificó la clave correctamente, ya podés ingresar desde <Link to="/login" exact component={Login}> aquí</Link>.</p>
                            </p>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}