import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useNavigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

export default function SuccessMessage({email, }) {

    const navigate = useNavigate()

    const returnToLogin = () => {

        navigate("/login")

    }

    return (

        <>
             <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <p align="center">
                                <i className="pi pi-check-circle" style={{ color: 'green', fontSize: '2rem' }}></i>
                                <p>¡Email Enviado!</p>
                                <p>Acabamos de enviarte un email a <span>{email}</span> con las instrucciones para recuperar tu clave</p>
                                <p><Button type="submit" variant="outlined" color="success" onClick={returnToLogin} endIcon={<CheckCircleOutlineIcon />}>
                                    ENTENDIDO 
                                </Button></p>
                            </p>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}