import { createSlice } from "@reduxjs/toolkit"


export const recuperarClaveSlice = createSlice({
    name: 'recuperar-clave',
    initialState: {
        email: null
    },
    reducers: {
        sendEmail: (state, action) => {
            state.email = action.payload.email
        }
    }
});

export const { sendEmail } = recuperarClaveSlice.actions
