import React from 'react'
import '../../assets/css/index.css'
import moment from "moment/min/moment-with-locales";
import { useGetCronogramasMutation } from '../../store/api/endpoints/cronogramas.js'
import { getCronogramas } from '../../store/slices/cronogramas/index.js'
import { useEffect, useState } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { Badge } from 'primereact/badge';

import { useSelector } from 'react-redux'
import { loginOptions } from "../../store/slices/auth"
import Forbidden from '../../components/vistas/auth/Forbidden'

const Cronograma = () => {

const [cronogramas, setCronogramas] = useState([])

let currMonthName  = moment().locale('es').format('MMMM');
let nextMonthName  = moment().locale('es').subtract(-1, "month").format('MMMM');

const [triggerGetCronogramas, 
      
    {
      data: getCronogramasConfig, // Es la respuesta del endpoint cuando devuelve bien
      error: errorCronogramas, // Es la respuesta del endpoint cuando devuelve algún error
      isLoading: isLoadingCronogramas, // 
      isUninitialized: isUninitializedCronogramas, // Se ejecuta antes del isLoading
      isError: isErrorCronogramas, // Si da error la respuesta
      isSuccess: isSuccessCronogramas // Si da bien la respuesta
    }
   
  ] = useGetCronogramasMutation()

  useEffect(()=>{
      
    triggerGetCronogramas()

  }, [])


    return (
        <>
            <div align="center">
                <div style={{ width: '90%'}}>
                    <div style={{ marginTop: "50px" }}></div>
                        <div class="grid" style={{ width: '90%' }}>
                            <div class="col-12 md:col-12 lg:col-12">
                                <p class="text-5xl lg:text-8xl md:text-7xl" style={{ fontWeight: "bold", color: "#b08372", fontSize: '120px', margin: 0 }}>CRONOGRAMA</p>
                                <p class="text-3xl lg:text-7xl md:text-5xl" style={{ fontWeight: "bold", color: "black", fontSize: '80px', margin: 0, display: "flex", justifyContent: "center" }}>{currMonthName.toUpperCase()} <p style={{ fontWeight: "bold", color: "#b08372", margin: 0 }}> | </p> {nextMonthName.toUpperCase()}</p>
                            </div>
                        </div>
                        <div style={{ height: 100 }}></div>
                        <div class="grid">

                            { isLoadingCronogramas &&

                                <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            }
                            
                            { isSuccessCronogramas &&

                                <div class="grid cronogramas">
                            
                                {
                                    getCronogramasConfig.map((item, index) => {
                                    
                                        return(

                                        <>
                                            
                                            <div class="col-12 md:col-6 lg:col-6">
                                                <div class="grid">
                                                    <div class="col-12 md:col-12 lg:col-4">
                                                        <a alt="" href="" className="block-icon">
                                                            <img style={{ height: "auto" , width: "100%", border: "4px solid #b08372" }} src={item.imagen}/>
                                                            <Badge className="icon-tag" size="xlarge" value={moment(item.fechayhora).format("DD")}></Badge>
                                                        </a> 
                                                    </div>
                                                    <div class="col-12 md:col-12 lg:col-7">
                                                        <p class="text-3xl lg:text-4xl md:text-4xl text-left" style={{ fontWeight: "bold", margin: 5 }}>{item.titulo.toUpperCase()}</p>
                                                        <p class="text-2xl lg:text-3xl md:text-3xl text-justify md:text-justify" style={{ margin: 5 }}>{item.descripcion}</p>
                                                        <div class="grid">
                                                            <div class="col-6 md:col-6 lg:col-6">
                                                                <Divider type="solid" layout="horizontal"/>
                                                            </div>
                                                        </div>
                                                        <p class="text-2xl lg:text-2xl md:text-2xl text-justify md:text-justify" style={{ fontWeight: "bold", margin: 5 }}>{item.modalidad.toUpperCase()}</p>
                                                        <p class="text-2xl lg:text-2xl md:text-2xl text-left" style={{ fontWeight: "bold" }}>{moment(item.fechayhora).locale('es').format("LLLL").toUpperCase()}</p>
                                                    </div>
                                                    <div class="col-12 md:col-12 lg:col-1"></div>
                                                </div>
                                            </div>
                                        </>

                                        )
                                        
                                    })
                                }
                            </div>
                            }
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div class="grid" style={{ width: '90%' }}>
                            <div class="col-12">
                                <Button label="FORMULARIO DE INSCRIPCIÓN A WORKSHOPS" className="unBoton" onClick={() => window.open("https://forms.gle/mWZBrt7eV4zAKirP8", '_blank')} size="large" style={{ borderRadius: 50, backgroundColor: "#8e8583", borderColor: "#8e8583", fontSize: 30 }}/>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                </div>
            </div>
        </>
    )
}

export default Cronograma
