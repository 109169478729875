import { tiendasApi } from '../api'

export const recuperarClaveTokenSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        recoveryEmailToken: builder.mutation({
            query: ({clave, clave_repite, token}) => ({
                url: `web/self/recuperar-clave/${token}`,
                method: 'PUT',
                body: { "clave": `${clave}`, "clave_repite": `${clave_repite}` }
            })
        }),
    })
})

export const {
    useRecoveryEmailTokenMutation,
} = recuperarClaveTokenSlice