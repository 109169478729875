import { createSlice } from "@reduxjs/toolkit"


export const cronogramasSlice = createSlice({
    name: 'cronogramas',
    initialState: {
        _id: null,
        titulo: null,
        descripcion: null,
        fechayhora: null,
        modalidad: null,
        imagen: null,
        __v: null
    },
    reducers: {
        getCronogramas: (state, action) => {
            state._id = action.payload._id 
            state.titulo = action.payload.titulo
            state.descripcion = action.payload.descripcion
            state.fechayhora = action.payload.fechayhora
            state.modalidad = action.payload.modalidad
            state.imagen = action.payload.imagen
            state.__v = action.payload.__v
        }
    }
});

export const { getCronogramas } = cronogramasSlice.actions

