import { useEffect } from "react"
import { useLocation, Navigate, Outlet, useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { useRefreshTokenMutation } from '../../../store/api/endpoints/refresh-token'
import { setCredentials, loginOptions } from "../../../store/slices/auth"


const RequireAuth = () => {

    //const navigate = useNavigate()
    //const urlParams = useParams()
    //const location = useLocation()

    const [triggerRefreshToken, { isLoading }] = useRefreshTokenMutation()
    const dispatch = useDispatch()

    const userLogin = useSelector(loginOptions)

    useEffect(() => {
        if (localStorage.getItem("token")) {
            usarTokenDeLocalStorage()
        } else {
            localStorage.removeItem("token")
        }
    }, [userLogin.token])


    const usarTokenDeLocalStorage = async () => {
        try {
            const userToken = await triggerRefreshToken({token: localStorage.getItem("token")}).unwrap()
            dispatch(setCredentials(userToken))
        } catch (err) {
            localStorage.removeItem("token")
        }
    }

    return (<Outlet />)
}

export default RequireAuth