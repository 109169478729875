import React, { useState } from "react"
import { InputTextarea } from "primereact/inputtextarea"
import { FloatLabel } from "primereact/floatlabel"

import '../../assets/css/form.css'

export default function Textarea({ tituloMini, value, onChange, placeHolder, icon, helperText, required, readonly, disabled, error, rows = 5 }) {

    return (
        <>
            {tituloMini && <p className='tituloMini'>{tituloMini}</p>}
            <div className="card flex justify-content-center">
                <InputTextarea
                    autoResize
                    className="w-full text-left"
                    id="description"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    rows={rows}
                    cols={30}
                    aria-describedby="username-help"
                    placeholder={placeHolder}
                    style={{ width: '100%' }}
                    required={required}
                    disabled={disabled}
                    invalid={error}
                    readOnly={readonly}
                />
            </div>
        </>
    )

}