import { createSlice } from "@reduxjs/toolkit";

export const refreshTokenSlice = createSlice({
    name: 'refresh-token',
    initialState: {
        nombre: null,
        email: null,
        token: null
    },
    reducers: {
        refreshToken: (state, action) => {
            state.nombre = action.payload.nombre;
            state.email = action.payload.email;
            state.token = action.payload.token;
            localStorage.setItem("token", action.payload.token);
        }
    }
});

export const { refreshToken } = refreshTokenSlice.actions;




