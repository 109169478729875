import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API,
  //credentials: 'include',
})


export const tiendasApi = createApi({
  reducerPath: 'tiendasApi',
  baseQuery: baseQuery,
  keepUnusedDataFor: 20,
  endpoints: builder => ({}),
})
