import { createSlice } from "@reduxjs/toolkit"


export const recuperarClaveTokenSlice = createSlice({
    name: 'recuperar-clave-token',
    initialState: {
        clave: null,
        clave_repite: null
    },
    reducers: {
        recoveryPasswordToken: (state, action) => {
            state.clave = action.payload.clave
            state.clave_repite = action.payload.clave_repite
        }
    }
});

export const { recoveryPasswordToken } = recuperarClaveTokenSlice.actions
