import React, { useEffect } from "react"
import { Menubar } from 'primereact/menubar'
import { PrimeIcons } from 'primereact/api'
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
 
import { useDispatch, useSelector } from 'react-redux'
import { loginOptions } from "../../store/slices/auth"


export default function Menu() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const goToLogin = () => {
        navigate("/login")
    }

    const goToPerfil = () => {
        navigate("/mi-perfil")
    }

   

    const userLogin = useSelector(loginOptions)

    const items = [
        {
            label: 'Home',
            icon: PrimeIcons.HOME,
            command: () => navigate('/')
        },
        {
            label: 'Suscribirme',
            icon: 'pi pi-user-plus',
            command: () => navigate('/nuevo-suscriptor')
        },
        {
            label: 'Cronograma',
            icon: 'pi pi-calendar',
            command: () => navigate('/cronograma')
        },
        {
            label: 'Descuentos',
            icon: 'pi pi-shopping-bag',
            command: () => navigate('/descuentos')
        },
        {
            label: 'Contacto',
            icon: 'pi pi-phone',
            command: () => navigate('/contacto')
        }
    ]

    /*
    if(userLogin.token){

        items.pop()

        items.push(
            {
                label: 'Descuentos',
                icon: 'pi pi-shopping-bag',
                command: () => navigate('/descuentos')
            },
            {
                label: 'Contacto',
                icon: 'pi pi-phone',
                command: () => navigate('/contacto')
            }
        )


    }*/

        
    const start = <img alt="logo" src="https://cafira.sfo2.digitaloceanspaces.com/red-cafira/logo.jpg" height="40" className="mr-2"></img>;

    if(userLogin.token) return (
    
        <div className="card">
            <Menubar model={items} start={start} 
            
            end={
            
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-user" label={userLogin.nombre} className="unBoton" size="smaill" onClick={goToPerfil} style={{ borderRadius: 30, backgroundColor: "#8e8583", borderColor: "#8e8583", fontSize: 15 }}/>
                </div>
        
            }/>  
        </div> 
    )

    if(!userLogin.token) return (
        
        <div className="card">
            <Menubar model={items} start={start} 
            
            end={
            
                <div className="flex align-items-center gap-2">
                    <Button icon="pi pi-sign-in" label="Login" className="unBoton" size="smaill" onClick={goToLogin} style={{ borderRadius: 30, backgroundColor: "#8e8583", borderColor: "#8e8583", fontSize: 15 }}/>
                </div>
            }/>  
        </div> 
    )
}
