import { useState } from 'react'

import { InputText } from 'primereact/inputtext'
import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"

import '../../assets/css/form.css'

export default function Input({ tituloMini, value, onChange, label, placeHolder, icon, helperText, required, restricciones, autoFocus, disabled, readonly, type }) {

    const [error, setError] = useState(false)
    const [helperTextTemp, setHelperTextTemp] = useState(helperText)

    const soloNumeros = (e) => {
        return String(e.target.value)
            .match(/^[0-9]+$/i);
    }

    const soloNumerosDecimales = (e) => {
        return String(e.target.value)
            .match(/^[+-]?\d+(\.\d+)?$/);
    }

    const email = (e) => {
        return String(e.target.value)
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    const alfanumerico = (e) => {
        return String(e.target.value)
            .match(/^[a-z0-9]+$/i);
    }

    const validarRestricciones = (e) => {

        let todoValido = true;

        if (restricciones?.includes('soloNumeros') && !soloNumeros(e)) {
            setHelperTextTemp("¡Sólo números!");
            todoValido = false;
        }

        if (restricciones?.includes('soloNumerosDecimales') && !soloNumerosDecimales(e)) {
            setHelperTextTemp("¡Sólo números decimales!");
            todoValido = false;
        }

        if (restricciones?.includes('email') && !email(e)) {
            setHelperTextTemp("¡Ingresa un email válido!");
            todoValido = false;
        }

        if (restricciones?.includes('alfanumerico') && !alfanumerico(e)) {
            setHelperTextTemp("¡Sólo letras y números!");
            todoValido = false;
        }


        if (todoValido) {
            setError(false);
            setHelperTextTemp(helperText);
        } else {
            setError(true);
        }
        onChange(e.target.value);

    }

    return (
        <div className="card flex justify-content-left text-left">
            <div className="flex flex-column gap-1" style={{ width: '100%' }}>
                {tituloMini && <p className='tituloMini'>{tituloMini}</p>}
                <IconField iconPosition="left" style={{ width: '100%' }}>
                    <InputIcon className={icon}> </InputIcon>
                    <InputText
                        id="unInput"
                        className='w-full'
                        aria-describedby="username-help"
                        placeholder={placeHolder}
                        value={value}
                        onChange={(e) => validarRestricciones(e)}
                        required={required}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        type={type}
                        invalid={error}
                        readOnly={readonly}
                    />
                </IconField>
                {
                    helperTextTemp && <small id="username-help">
                        {helperTextTemp}
                    </small>
                }
            </div>
        </div>
    )

}