import { tiendasApi } from '../api'

export const perfilSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        userProfile: builder.mutation({
            query: ({token}) => ({
                url: 'web/self',
                method: 'GET',
                headers: {
                    'Authorization': token,
                }
            })
        }),
    })
})

export const {
    useUserProfileMutation,
} = perfilSlice