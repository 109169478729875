import { tiendasApi } from '../api'

export const refreshTokenSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        refreshToken: builder.mutation({
            query: ({token}) => ({
                url: 'web/self/refresh-token',
                method: 'GET',
                headers: {
                    'Authorization': token,
                },
            })
        })
    })
})

export const {
    useRefreshTokenMutation
} = refreshTokenSlice