import { React, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { recoveryPasswordToken } from '../../../store/slices/recuperar-clave-token'
import { useRecoveryEmailTokenMutation } from '../../../store/api/endpoints/recuperar-clave-token'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'

import PasswordRecoveryMessage from '../../elementos/PasswordRecoveryMessage'
import ErrorMessage from '../../elementos/ErrorMessage'

import Input from '../../elementos/Input'
import Loading from '../../elementos/Loading'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import '../../../assets/css/Login.css'

const RecuperarClaveToken = () => {

    const urlParams = useParams()

    const [clave, setClave] = useState("")
    const [clave_repite, setClaveRepite] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        setErrorMessage("")
    }, [clave, clave_repite])

    const [triggerRecoveryPasswordToken, { isLoading: isLoadingRecoveryPassword, isSuccess: isSuccessRecoveryPassword}] = useRecoveryEmailTokenMutation()

    const submitForm = async (e) => {

        e.preventDefault()

        try {
            const passwordRecovery = await triggerRecoveryPasswordToken({clave: clave, clave_repite: clave_repite, token: urlParams.token}).unwrap()
            dispatch(recoveryPasswordToken(passwordRecovery))

        } catch (err) {
            setErrorMessage(err)
        }

    }

    if (isLoadingRecoveryPassword) return (<Loading />)

    if (errorMessage) return (<ErrorMessage error={errorMessage.data.message} />)
    
    if (isSuccessRecoveryPassword) return (<PasswordRecoveryMessage />)

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <form onSubmit={submitForm}>
                                <h2 style={{ letterSpacing: '1px', fontWeight: '600' }}>Recuperá tu clave</h2>
                                <br />

                                <Input
                                    tituloMini="Nueva Contraseña"
                                    type="password"
                                    value={clave}
                                    onChange={setClave}
                                    icon="pi pi-key"
                                    required
                                />

                                <Input
                                    tituloMini="Repetí Nueva Contraseña"
                                    type="password"
                                    value={clave_repite}
                                    onChange={setClaveRepite}
                                    icon="pi pi-key"
                                    required
                                />
                                
                                <br />
                                <p><Button type="submit" variant="outlined" color="success" endIcon={<KeyboardArrowRightIcon />}>
                                    Enviar 
                                </Button></p>
                            </form>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}

export default RecuperarClaveToken