import { tiendasApi } from '../api'

export const recuperarClaveSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        recoveryEmail: builder.mutation({
            query: ({email}) => ({
                url: 'web/self/recuperar-clave',
                method: 'POST',
                body: { "email": `${email}` }
            })
        }),
    })
})

export const {
    useRecoveryEmailMutation,
} = recuperarClaveSlice