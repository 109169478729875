import { React, useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from '../../../store/slices/auth'
import { useLoginMutation } from '../../../store/api/endpoints/login'

import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'


import Input from '../../elementos/Input'
import Loading from '../../elementos/Loading'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import ErrorMessage from '../../elementos/ErrorMessage'

import '../../../assets/css/Login.css'
import RecuperarClave from './RecuperarClave'

const Login = () => {

    const [email, setEmail] = useState("")
    const [contrasena, setContrasena] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const navigate = useNavigate()

    const [triggerLogin, { isLoading: isLoadingLogin}] = useLoginMutation()
    const dispatch = useDispatch()

    useEffect(() => {
        setErrorMessage("")
    }, [email, contrasena])

    const submitForm = async (e) => {

        e.preventDefault()

        try {
            const userLogin = await triggerLogin({ email, contrasena }).unwrap()
            dispatch(setCredentials(userLogin))
            setEmail("")
            setContrasena("")
            navigate("/") //consultar a donde quieren hacer la redirección
        } catch (err) {
            setErrorMessage(err)
        }

    }

    if (isLoadingLogin) return (<Loading />)

    if (errorMessage) return (<ErrorMessage error={errorMessage.data.message} />)

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className="login">
                            <form onSubmit={submitForm}>
                                <h2 style={{ letterSpacing: '1px', fontWeight: '600' }}>Inicia Sesión</h2>
                                <Input
                                    tituloMini="Email"
                                    type="text"
                                    value={email}
                                    onChange={setEmail}
                                    icon="pi pi-at"
                                    required
                                />

                                <br />
                                <br />

                                <Input
                                    tituloMini="Contraseña"
                                    type="password"
                                    value={contrasena}
                                    onChange={setContrasena}
                                    icon="pi pi-key"
                                    required
                                />

                                <br />
                                <br />

                                <p>¿Olvidaste tu clave? Hace click <Link to="/recuperar-clave" exact component={RecuperarClave}> aquí</Link></p>
                                
                                <br />

                                <h3 className="error">{errorMessage ? errorMessage : ''}</h3>
                                <p><Button type="submit" variant="outlined" color="success" endIcon={<KeyboardArrowRightIcon />}>
                                    Ingresar 
                                </Button></p>
                            </form>
                        </div>
                    </Grid>
                </Grid >
            </Container>
        </>
    )

}

export default Login