import { React } from 'react'
import banner1 from "../../assets/images/home-1.jpg"
import banner2 from "../../assets/images/home-2.jpg"
import banner3 from "../../assets/images/home-3.jpg"
import facebook from "../../assets/images/facebook.png"
import instagram from "../../assets/images/instagram.png"
import youtube from "../../assets/images/youtube.png"
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import '../../assets/css/index.css'

import { getUsuarioNombre } from "../../store/slices/auth"
import { useSelector } from "react-redux"


export default function Home() {


    return (
        <>
            <p></p>
            <div class="grid">
                <div class="col-12 md:col-12" align="center">
                    <div class="col-12 md:col-12" style={{ maxWidth: '1400px' }}>
                        <div align="left" class="col-12 lg:col-12">
                            <div class="grid" id="bannerHomeContenedor">
                                <div align="left" class="col-12 lg:col-6" id="bannerHomeColumnaIzquierdaTitulo">
                                    <div id="bannerHomeTitulo">
                                        <p className="text-7xl lg:text-7xl titulo1" >SUMATE A RED CAFIRA</p>
                                        <p className="text-3xl lg:text-5xl subtitulo1">COMUNIDAD <span className='simbolo'>+</span> DISEÑO</p>
                                    </div>
                                    <p id="bannerHomeColumnaIzquierdaTexto" className='text-2xl lg:text-3xl'>SI SOS ARQUITECTO, DISEÑADOR, COMERCIANTE O TENES COMERCIO, ADHERITE A LA RED FEDERAL MÁS ÁMPLIA DEL RUBRO</p>
                                    <p align="center"><Button className="text-lg lg:text-3xl unBoton" label="ADHERITE" onClick={() => window.location.href = '/nuevo-suscriptor'} size="large" /></p>
                                </div>
                                <div align="left" className="col-12 lg:col-6" id="bannerHomeColumnaDerecha"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div align="center">
                <div class="col-12" style={{ maxWidth: '1400px' }}>
                    <div class="grid">
                        <div className='col-12 md:col-4'>
                            <img src={banner1} width='100%' />
                        </div>
                        <div className='col-12 md:col-4'>
                            <img src={banner2} width='100%' />
                        </div>
                        <div className='col-12 md:col-4'>
                            <img src={banner3} width='100%' />
                        </div>
                    </div>
                    <div class="grid">
                        <div className='col-12'>
                            <p>
                                <Button label="CRONOGRAMA MENSUAL DE ACTIVIDADES Y BENEFICIOS" className="unBoton" onClick={() => window.location.href = '/cronograma'} size="large" style={{ borderRadius: 50, backgroundColor: "#8e8583", borderColor: "#8e8583", fontSize: 30 }} />
                            </p>
                        </div>
                    </div>
                    <div class="grid">
                        <div className='col-12'>
                            <img style={{ width: '100%' }} src='https://cafira.sfo2.digitaloceanspaces.com/red-cafira/home2/2.jpg' />
                        </div>
                    </div>
                </div>
            </div>



        </>
    )

}