import { tiendasApi } from '../api'

export const descuentosSlice = tiendasApi.injectEndpoints({
    endpoints: builder => ({
        getDescuentos: builder.mutation({
            query: () => ({
                url: 'web/descuentos',
                method: 'GET'
            })
        }),
    })
})

export const {
    useGetDescuentosMutation,
} = descuentosSlice