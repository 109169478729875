import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { logOut } from "../../store/slices/auth"
import { loginOptions } from "../../store/slices/auth"
import { useSelector } from "react-redux"
import { useUserProfileMutation } from '../../store/api/endpoints/perfil'
import Forbidden from '../../components/vistas/auth/Forbidden'

import { Button } from 'primereact/button'
import Textarea from '../elementos/Textarea'
import { Divider } from 'primereact/divider'

import Input from '../elementos/Input'
import Loading from '../elementos/Loading'


const Perfil = () => {

    //const [errorMessage, setErrorMessage] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userLogin = useSelector(loginOptions)

    const logout = () => {
        dispatch(logOut())
        navigate("/")
    }

    useEffect(() => {

        triggerGetUserProfile({token: userLogin.token})

    }, [])

    const [triggerGetUserProfile,
        
        {
            data: responseGetUserProfile,
            isUninitialized: isUninitializedGetUserProfile,
            isLoading: isLoadingGetUserProfile,
            isSuccess: isSuccessGetUserProfile,
            isError: isErrorGetUserProfile,
            error: errorDataGetUserProfile,
        }

    ] = useUserProfileMutation()



    if(!userLogin.token) return (<Forbidden />)

    if (isLoadingGetUserProfile) return (<Loading />)

    if (isSuccessGetUserProfile)

    return (
        <>
            <div align="center" className='contenedor'>
                <form>
                    <div className="grid" style={{ maxWidth: '900px' }}>
                        <div className="col-12">
                            <p class="text-5xl text-center font-bold">Mi Perfil</p>
                        </div>
                        <div className="col-12">
                            <Divider />
                        </div>
                        <div className="col-12 md:col-4">
                            <Input
                                tituloMini="Nombre y Apellido"
                                type="text"
                                value={responseGetUserProfile.nombre}
                                icon="pi pi-user"
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-2">
                            <Input
                                tituloMini="DNI"
                                type="tel"
                                value={responseGetUserProfile.dni}
                                icon="pi pi-id-card"
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-3">
                            <Input
                                tituloMini="Email"
                                type="email"
                                value={responseGetUserProfile.email}
                                icon="pi pi-envelope"
                                restricciones={['email']}
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-3">
                            <Input
                                tituloMini="Celular"
                                type="tel"
                                value={responseGetUserProfile.telefono}
                                icon="pi pi-whatsapp"
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <Input
                                tituloMini="Empresa"
                                type="text"
                                value={responseGetUserProfile.empresa}
                                icon="pi pi-briefcase"
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <Input
                                tituloMini="CUIT/CUIL"
                                type="tel"
                                value={responseGetUserProfile.cuit}
                                icon="pi pi-desktop"
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <Input
                                tituloMini="Condición AFIP"
                                value={responseGetUserProfile.condicion_afip}
                                icon="pi pi-file"
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <Input
                                tituloMini="¿Comerciante o profesional?"
                                type="text"
                                value={responseGetUserProfile.comerciante}
                                icon="pi pi-users"
                                readonly={true}
                            />
                        </div>
                        <div className="col-12 md:col-8">
                            <Textarea
                                tituloMini="¿Por quién llegaste a Red Cafira?"
                                value={responseGetUserProfile.como_llegaste}
                                icon="pi pi-whatsapp"
                                readonly={true}
                            />
                        </div>
                        <Divider />
                        <div className="col-12">
                            <Button
                                label="Cerrar Sesión"
                                severity="danger"
                                type="submit"
                                icon="pi pi-sign-out"
                                iconPos='left'
                                onClick={logout}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}

export default Perfil