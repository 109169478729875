import { React, useEffect, useState } from 'react'
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'

import { useCreateSuscriptorMutation } from '../../store/api/endpoints/pagoticApiSlice'


import { Button } from 'primereact/button'
import { Divider } from 'primereact/divider'
import { Checkbox } from "primereact/checkbox"

import Loading from '../elementos/Loading'
import Input from '../elementos/Input'
import Select from '../elementos/Select'
import Textarea from '../elementos/Textarea'
import { useRef } from 'react'


export default function NuevoSuscriptor() {

    const navigate = useNavigate()

    const [dni, setDNI] = useState('')
    const [nombre, setNombre] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [email, setEmail] = useState('')
    const [telefono, setTelefono] = useState('')
    const [cuit, setCuit] = useState('')
    const [condicion_afip, setCondicionAfip] = useState('')
    const [selected, setSelected] = useState('')
    const [comerciante, setComerciante] = useState('')
    const [como_llegaste, setComoLlegaste] = useState('')
    const [terminos, setTerminos] = useState(false)

    const toast = useRef(null)

    const [
        crearRegistro,
        {
            data: responseCrearRegistro,
            isUninitialized: isUninitializedCrearRegistro,
            isLoading: isLoadingCrearRegistro,
            isSuccess: isSuccessCrearRegistro,
            isError: isErrorCrearRegistro,
            error: errorDataCrearRegistro,
        }
    ] = useCreateSuscriptorMutation()

    const handleSubmit = (e) => {

        e.preventDefault()

        let body = {
            nombre,
            empresa,
            email,
            telefono,
            dni,
            cuit,
            condicion_afip: condicion_afip.code,
            comerciante,
            como_llegaste,
            terminos,
        }

        crearRegistro({ body: body })

    }

    const getSelection = (e) => {
        setSelected(e.label);
        setComerciante(e.label)
      };



    useEffect(() => {
        if (isSuccessCrearRegistro) {
            window.location.href = responseCrearRegistro.urlPago
        }
    }, [isSuccessCrearRegistro])

    useEffect(() => {
        if (isErrorCrearRegistro) {
            toast.current.show({ severity: 'warn', summary: '¡Cuidado!', detail: errorDataCrearRegistro.data.message })
        }
    }, [isErrorCrearRegistro])

    if (isLoadingCrearRegistro || isSuccessCrearRegistro) return (<Loading />)

    return (
        <>
            <Toast ref={toast} />
            <div align="center" className='contenedor'>
                <form onSubmit={handleSubmit}>
                    <div className="grid" style={{ maxWidth: '900px' }}>
                        <div className="col-12">
                            <p class="text-5xl text-center font-bold">¡Suscribite!</p>
                            <p class="text-base text-center md:text-left font-bold ">¿Cómo me suscribo?</p>
                            <p class="text-base text-left ">1. Completar el formulario con tus datos</p>
                            <p class="text-base text-left ">2. Ingresar una tarjeta de crédito para vincular el débito automático de la suscripción.</p>
                            <p class="text-base text-left ">3. Recibirás un correo electrónico para generar tu clave de acceso.</p>
                        </div>
                        <div className="col-12">
                            <Divider />
                        </div>
                        <div className="col-12 md:col-4">
                            <Input
                                tituloMini="Nombre y Apellido"
                                type="text"
                                value={nombre}
                                onChange={setNombre}
                                icon="pi pi-user"
                                required
                            />
                        </div>
                        <div className="col-12 md:col-2">
                            <Input
                                tituloMini="DNI"
                                type="tel"
                                value={dni}
                                onChange={setDNI}
                                icon="pi pi-id-card"
                                required
                            />
                        </div>
                        <div className="col-12 md:col-3">
                            <Input
                                tituloMini="Email"
                                type="email"
                                value={email}
                                onChange={setEmail}
                                icon="pi pi-envelope"
                                restricciones={['email']}
                                required
                            />
                        </div>
                        <div className="col-12 md:col-3">
                            <Input
                                tituloMini="Celular"
                                type="tel"
                                value={telefono}
                                onChange={setTelefono}
                                icon="pi pi-whatsapp"
                                required
                            />
                        </div>
                        <div className="col-12 md:col-4">
                            <Select
                                tituloMini="¿Estudiante, Comerciante o Profesional?"
                                value={comerciante}
                                onChange={getSelection}
                                icon="pi pi-users"
                                opciones={[
                                    { label: 'Comerciante', code: 'Comerciante' },
                                    { label: 'Profesional', code: 'Profesional' },
                                    { label: 'Estudiante', code: 'Estudiante' }
                                ]}
                            /> 
                        </div>
                        {
                            selected !== "Estudiante" &&

                            <>
                            <div className="col-12 md:col-4">
                            <Input
                                tituloMini="Empresa"
                                type="text"
                                value={empresa}
                                onChange={setEmpresa}
                                icon="pi pi-briefcase"
                                required
                            />
                            </div>
                            <div className="col-12 md:col-4">
                            <Input
                                tituloMini="CUIT/CUIL"
                                type="tel"
                                value={cuit}
                                onChange={setCuit}
                                icon="pi pi-desktop"
                                required
                            />  
                            </div>
                            <div className="col-12 md:col-4">
                            <Select
                                tituloMini="Condición AFIP"
                                value={condicion_afip}
                                onChange={setCondicionAfip}
                                icon="pi pi-file"
                                opciones={[
                                    { label: 'IVA Responsable Inscripto', code: 'IVA Responsable Inscripto' },
                                    { label: 'IVA Responsable no Inscripto', code: 'IVA Responsable no Inscripto' },
                                    { label: 'IVA no Responsable', code: 'IVA no Responsable' },
                                    { label: 'IVA Sujeto Exento', code: 'IVA Sujeto Exento' },
                                    { label: 'Consumidor Final', code: 'Consumidor Final' },
                                    { label: 'Responsable Monotributo', code: 'Responsable Monotributo' },
                                    { label: 'Sujeto no Categorizado', code: 'Sujeto no Categorizado' },
                                    { label: 'Proveedor del Exterior', code: 'Proveedor del Exterior' },
                                    { label: 'Cliente del Exterior', code: 'Cliente del Exterior' },
                                    { label: 'IVA Liberado – Ley Nº 19.640', code: 'IVA Liberado – Ley Nº 19.640' },
                                    { label: 'IVA Liberado – Ley Nº 19.640', code: 'IVA Liberado – Ley Nº 19.640' },
                                    { label: 'Pequeño Contribuyente Eventual', code: 'Pequeño Contribuyente Eventual' },
                                    { label: 'Monotributista Social', code: 'Monotributista Social' },
                                    { label: 'Pequeño Contribuyente Eventual Social', code: 'Pequeño Contribuyente Eventual Social' },
                                ]}
                            />
                            </div>
                        </>
                        }
                        
                        <div className="col-12 md:col-8">
                            <Textarea
                                tituloMini="¿Por quién llegaste a Red Cafira?"
                                value={como_llegaste}
                                onChange={setComoLlegaste}
                                icon="pi pi-whatsapp"
                                required
                            />
                        </div>
                        <div className="col-12">
                            <p><Checkbox onChange={e => setTerminos(e.checked)} checked={terminos}></Checkbox> Acepto los <a href="https://cafira.sfo2.digitaloceanspaces.com/red-cafira/terminos-y-condiciones.pdf" target='_blank'>Términos y Condiciones</a></p>
                        </div>
                        <Divider />
                        <div className="col-12">
                            <Button
                                label="Continuar"
                                severity="success"
                                type="submit"
                                icon="pi pi-chevron-right"
                                iconPos='right'
                            />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}