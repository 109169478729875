import React from 'react'
import '../../assets/css/index.css'
import moment from "moment/min/moment-with-locales";
import { useGetDescuentosMutation } from '../../store/api/endpoints/descuentos.js'
import { useGetProductosMutation } from '../../store/api/endpoints/productos.js'
import { useEffect, useState } from 'react'
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

import { useSelector } from 'react-redux'
import { loginOptions } from "../../store/slices/auth"
import Forbidden from '../../components/vistas/auth/Forbidden'

const Descuentos = () => {


let currMonthName  = moment().locale('es').format('MMMM');
let nextMonthName  = moment().locale('es').subtract(-1, "month").format('MMMM');


const [triggerGetDescuentos, 
      
    {
      data: getDescuentosConfig, // Es la respuesta del endpoint cuando devuelve bien
      error: errorDescuentos, // Es la respuesta del endpoint cuando devuelve algún error
      isLoading: isLoadingDescuentos, // 
      isUninitialized: isUninitializedDescuentos, // Se ejecuta antes del isLoading
      isError: isErrorDescuentos, // Si da error la respuesta
      isSuccess: isSuccessDescuentos // Si da bien la respuesta
    }
   
  ] = useGetDescuentosMutation()

  const [triggerGetProductos, 
      
    {
      data: getProductosConfig, // Es la respuesta del endpoint cuando devuelve bien
      error: errorProductos, // Es la respuesta del endpoint cuando devuelve algún error
      isLoading: isLoadingProductos, // 
      isUninitialized: isUninitializedProductos, // Se ejecuta antes del isLoading
      isError: isErrorProductos, // Si da error la respuesta
      isSuccess: isSuccessProductos // Si da bien la respuesta
    }
   
  ] = useGetProductosMutation()

  
  useEffect(()=>{
      
    triggerGetDescuentos()
    triggerGetProductos()

  }, [])

  /*
  
  useEffect(()=>{
      
    triggerGetDescuentos({token: userLogin.token})
    triggerGetProductos({token: userLogin.token})

  }, [])


  const userLogin = useSelector(loginOptions)

  if(!userLogin.token) return (<Forbidden />) */


    return (
        <>
            <div align="center">
                <div style={{ width: '90%'}}>
                    <div style={{ marginTop: "50px" }}></div>
                        <div class="grid" style={{ width: '90%' }}>
                            <div class="col-12 md:col-12 lg:col-12">
                                <p class="text-5xl lg:text-8xl md:text-7xl" style={{ fontWeight: "bold", color: "#b08372", fontSize: '120px', margin: 0 }}>DESCUENTOS</p>
                                <p class="text-3xl lg:text-7xl md:text-5xl" style={{ fontWeight: "bold", color: "black", fontSize: '80px', margin: 0, display: "flex", justifyContent: "center" }}>{currMonthName.toUpperCase()} <p style={{ fontWeight: "bold", color: "#b08372", margin: 0 }}> | </p> {nextMonthName.toUpperCase()}</p>
                            </div>
                        </div>
                        <div style={{ height: 100 }}></div>
                        <div class="grid">

                            { isLoadingDescuentos &&

                                <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            }
                            
                            { isSuccessDescuentos &&

                            <div class="grid">

                            {

                                getDescuentosConfig.map((item, index) => {
                                
                                    return(

                                        <>      
                                            <div class="col-12 md:col-12 lg:col-12">
                                                <div class="grid">
                                                    <div class="col-12 md:col-1 lg:col-1"></div>
                                                    <div class="col-12 md:col-12 lg:col-2">
                                                        <img style={{ height: "auto" , width: "100%", border: "4px solid #ae8686" }} src={item.imagen}/>
                                                    </div>
                                                    <div class="col-12 md:col-12 lg:col-4">
                                                        <p class="text-3xl lg:text-4xl md:text-4xl text-left" style={{ fontWeight: "bold", margin: 5 }}>{item.titulo.toUpperCase()}</p>
                                                        <p class="text-2xl lg:text-3xl md:text-3xl text-justify md:text-justify" style={{ margin: 5 }}>{item.descripcion}</p>
                                                    </div>
                                                    <div class="col-12 md:col-12 lg:col-1">
                                                        <Divider className="divider" type="solid" layout="vertical" />
                                                    </div>
                                                    <div class="col-12 md:col-12 lg:col-3">
                                                        <p class="text-2xl lg:text-3xl md:text-3xl font-light text-center" style={{ margin: 15}}>CONTACTO</p>
                                                        <p class="text-1xl lg:text-2xl md:text-2xl font-light text-center" style={{ margin: 5}}>DESDE: {moment(item.fechaInicio).format("DD/MM/YYYY")}</p>
                                                        <p class="text-1xl lg:text-2xl md:text-2xl font-light text-center" style={{ margin: 5}}>HASTA: {moment(item.fechaFin).format("DD/MM/YYYY")}</p>
                                                    </div>
                                                    <div class="col-12 md:col-1 lg:col-1"></div>
                                                </div>
                                            </div>
                                            <div class="col-12 md:col-12 lg:col-12">
                                                <div class="grid">
                                                    <div class="col-12 md:col-1 lg:col-1"></div>
                                                    <div class="col-12 md:col-10 lg:col-10">
                                                        <Divider type="solid" layout="horizontal"/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </>
                                    )
                                      
                                })
                            
                                }
                            </div>
                            }
                        </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <div class="grid">
                            <div class="col-12 md:col-12 lg:col-12">
                                <p class="text-5xl lg:text-8xl md:text-7xl" style={{ fontWeight: "bold", color: "black" }}>EXPOSITORES CAFIRA</p>
                            </div>
                        </div>
                        <div class="grid">
                                   
                        { isLoadingProductos &&

                        <ProgressSpinner style={{width: '100px', height: '100px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        
                        }

                        { isSuccessProductos &&
                
                            <div class="grid expositores" style={{ backgroundColor: "#b08372" }}>

                            {

                                getProductosConfig.map((item, index) => {

                                    return(

                                        <>  

                                            <div class="col-12 md:col-6 lg:col-6">
                                                <div class="grid">
                                                    <div class="col-12 md:col-12 lg:col-5">
                                                        <img style={{ height: "auto" , width: "100%", border: "4px solid white" }} src={item.imagen}/>
                                                    </div>
                                                    <div class="col-12 md:col-12 lg:col-7 text-center md:text-left" style={{ alignContent: "center" }} >
                                                        <p class="text-3xl lg:text-4xl md:text-3xl" style={{ fontWeight: "bold", color: "white" }}>{item.titulo.toUpperCase()}</p>
                                                        <Button className="focus unBoton" label="CAFIRA MARKET" onClick={() => window.open(item.url, '_blank')} size="large" style={{ borderRadius: 50, backgroundColor: "white", borderColor: "white", fontSize: 20, color: "black" }}/>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            

                                        </>
                                    )  
                                })
                            }
                        </div>
                        }
                    </div>
                        
            </div>
            
        </>
    )
}

export default Descuentos
